.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #1e4491;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: rgb(17, 2, 2);
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes flash {
  0%, 100% { opacity: 1; }
  50% { opacity: 0; }
}

.flashing-text {
  animation: flash 1s infinite;
}

@keyframes flashing {
  0%, 100% { opacity: 1; }
  50% { opacity: 0; }
}

.flashing-text {
  animation: flashing 1s linear infinite;
}



@keyframes scanVerticalDown {
  0%, 100% { top: -100%; } /* Start and end outside the top of the container */
  50% { top: 100%; } /* Move to the bottom of the container */
}

@keyframes scanVerticalUp {
  0%, 100% { bottom: -100%; } /* Start and end outside the bottom of the container */
  50% { bottom: 100%; } /* Move to the top of the container */
}

@keyframes scanHorizontalRight {
  0%, 100% { left: -100%; } /* Start and end outside the left of the container */
  50% { left: 100%; } /* Move to the right of the container */
}

@keyframes scanHorizontalLeft {
  0%, 100% { right: -100%; } /* Start and end outside the right of the container */
  50% { right: 100%; } /* Move to the left of the container */
}

.scanning-line.vertical-down {
  position: absolute;
  background-color: #4caf50; /* Green color, adjust as needed */
  height: 2px; /* Line thickness */
  width: 100%;
  animation: scanVerticalDown 5s infinite linear;
}

.scanning-line.vertical-up {
  position: absolute;
  background-color: #4caf50; /* Green color, adjust as needed */
  height: 2px; /* Line thickness */
  width: 100%;
  animation: scanVerticalUp 5s infinite linear;
}

.scanning-line.horizontal-right {
  position: absolute;
  background-color: #4caf50; /* Green color, adjust as needed */
  width: 2px; /* Line thickness */
  height: 100%;
  animation: scanHorizontalRight 5s infinite linear;
}

.scanning-line.horizontal-left {
  position: absolute;
  background-color: #4caf50; /* Green color, adjust as needed */
  width: 2px; /* Line thickness */
  height: 100%;
  animation: scanHorizontalLeft 5s infinite linear;
}

